<template>
    <a-card>
        <a-page-header
            title="新建表单模板"
        />

        <a-form-model
            ref="form"
            :model="form"
            :rules='rules'
            v-bind='layout'
        >
            <a-form-model-item label="模板类型" prop="formTemplateType">
                <a-select
                    v-model="form.formTemplateType"
                    placeholder="请选择品牌"
                    show-search
                    option-filter-prop="children"
                    style="width: 100%;"
                    @change="onChangeType"
                >
                    <a-select-option
                        v-for="item in stencilList"
                        :key="item.code"
                    >
                        {{ item.desc }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="模板标题" ref="templateName" prop='templateName'>
                <wordLimitInput 
                    :value="form.templateName"
                    :maxLength="20"
                    @onChangeInput="e => onChangeInput(e, 'templateName')"
                ></wordLimitInput>
            </a-form-model-item>
            <a-form-model-item label="按钮名称" ref="buttonText" prop='buttonText'>
                <wordLimitInput 
                    :value="form.buttonText"
                    :maxLength="8"
                    @onChangeInput="e => onChangeInput(e, 'buttonText')"
                ></wordLimitInput>
            </a-form-model-item>
            <a-form-model-item label="姓名标题" ref="nameLabel" prop='nameLabel'>
                <wordLimitInput 
                    :value="form.nameLabel"
                    :maxLength="20"
                    @onChangeInput="e => onChangeInput(e, 'nameLabel')"
                ></wordLimitInput>
            </a-form-model-item>
            <a-form-model-item label="电话标题" ref="telLabel" prop='telLabel'>
                <wordLimitInput 
                    :value="form.telLabel"
                    :maxLength="20"
                    @onChangeInput="e => onChangeInput(e, 'telLabel')"
                ></wordLimitInput>
            </a-form-model-item>
            <a-form-model-item label="城市标题" ref="cityLabel" prop='cityLabel' v-if="isShowCity">
                <wordLimitInput 
                    :value="form.cityLabel"
                    :maxLength="20"
                    @onChangeInput="e => onChangeInput(e, 'cityLabel')"
                ></wordLimitInput>
            </a-form-model-item>
            <a-form-model-item label="有效性验证" prop="validityType">
                <a-select
                    v-model="form.validityType"
                    placeholder="请选择有效性验证"
                    show-search
                    option-filter-prop="children"
                    style="width: 100%;"
                >
                    <a-select-option
                        v-for="item in verifyList"
                        :key="item.id"
                    >
                        {{ item.label }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="重复限制" prop='isUnique'>
                <a-radio-group v-model="form.isUnique">
                    <a-radio :value="1">不允许</a-radio>
                    <a-radio :value="2">允许</a-radio>
                </a-radio-group>
            </a-form-model-item>

            <a-form-model-item label=" " :colon="false">
                <a-space>
                    <base-button
                        :title="'取消'"
                        @onClickBtn="handleBack"
                    ></base-button>
                    <base-button
                        :type="'primary'"
                        :title="'提交'"
                        :loading="isSubmit"
                        :disabled="isSubmit"
                        @onClickBtn="handleSubmit"
                    ></base-button>
                </a-space>
            </a-form-model-item>
        </a-form-model>
    </a-card>
</template>

<script>
import wordLimitInput from '@/components/wordLimitInput'

export default {
    components: {
        wordLimitInput,
    },
    data() {
        return {
            id: this.$route.query.id,
            layout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 11 }
            },
            form: {
                formTemplateType: undefined,
                templateName: '',
                buttonText: '',
                nameLabel: '',
                telLabel: '',
                cityLabel: '',
                validityType: 1,
                isUnique: 1,
            },
            rules: {
                formTemplateType: [
                    { required: true, message: '请选择模板类型', trigger: 'change' }
                ],
                templateName: [
                    { required: true, message: '请填写模板标题', trigger: 'change' }
                ],
                buttonText: [
                    { required: true, message: '请填写按钮名称', trigger: 'change' }
                ],
                nameLabel: [
                    { required: true, message: '请填写姓名标题', trigger: 'change' }
                ],
                telLabel: [
                    { required: true, message: '请填写电话标题', trigger: 'change' }
                ],
                cityLabel: [
                    { required: true, message: '请填写城市标题', trigger: 'change' }
                ],
                validityType: [
                    { required: true, message: '请选择有效性验证', trigger: 'change' }
                ],
                isUnique: [
                    { required: true, message: '请选择重复限制', trigger: 'change' }
                ],
            },
            isSubmit: false,
            stencilList: [],
            verifyList: [
                { id: 2, label: '线索量优先' },
                { id: 1, label: '有效性优先' },
                { id: 3, label: '全不出' },
                // { id: 4, label: '智能验证' },
                { id: 5, label: '全出' },
            ],
            isShowCity: false
        }
    },
    created() {
        this.getStencilList()

        if(this.id != 0) {
            this.getDetail()
        }
    },
    methods: {
        //判断是否显示城市标题
        onChangeType(val) {
            let showCityList = [10, 20, 30, 40, 50]
            if(showCityList.indexOf(val) != '-1') {
                this.isShowCity = true
            } else {
                this.isShowCity = false
                this.form.cityLabel = ''
            }
        },
        getStencilList() {
            this.$api.core.materialManage.getStencilList().then(res => {
                if(res.code == 200) {
                    this.stencilList = res.data
                } else {
                    this.stencilList = []
                    console.error(`获取模版列表失败，${res}`)
                }
            })
        },
        onChangeInput(val, type) {
            this.form[type] = val
            this.$refs[type].onFieldChange()
        },
        handleBack() {
            let that = this
            this.$confirm({
                content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
                </div>,
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$router.push({
                        path: '/formManage'
                    })
                }
            })
        },
        handleSubmit() {
            this.$refs.form.validate((valid, obj) => {
                if (valid) {
                    this.isSubmit = true
                    this.form.enablePersonalAction = !!this.form.enablePersonalAction
                    
                    this.$api.core.materialManage.submitFormTemplateDetail( this.form ).then(res => {
                        this.isSubmit = false
                        if(res.code == 200) {
                            this.$message.success('提交成功')
                            this.$router.push({
                                path: '/formManage'
                            })
                        } else {
                            this.$message.error(`操作失败，内容填写未完成或模板标题重复`)
                        }
                    })
                }
            })
        },
    },
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
</style>